import React, {useRef} from "react";
import {Container, ModalBody, Span, Wrapper} from "./styles";
import {CONTACT_IFRAME_NAME, Form} from "components/Form/Form";
import {useContactData} from "lang/contact";
import {useEngContactData} from "lang/en/contact";
import {useScrollTo} from "shared/Hooks/useScrollTo";
import {useOnClickOutside} from "../../shared/Hooks/useOnClickOutside";

const Modal = ({active, closeModal, locale}) => {
  const data = useContactData();
  const engData = useEngContactData();
  const modalRef = useRef(null);
  useOnClickOutside(modalRef, closeModal);

  const handleScroll = useScrollTo(modalRef, true);

  return (
    <ModalBody className={active ? "active" : ""}>
      <Wrapper ref={modalRef}>
        <Container>
          <Form
            data={locale === "en" ? engData.form : data.form}
            modal="modal"
            name={CONTACT_IFRAME_NAME}
            src={locale === "en" ? process.env.GATSBY_CONTACT_FORM_URL_EN : process.env.GATSBY_CONTACT_FORM_URL_PL}
            handleScroll={handleScroll}
          />
          <Span onClick={closeModal}>
            {locale === "en" ? engData.form.close : data.form.close}
          </Span>
        </Container>
      </Wrapper>
    </ModalBody>
  );
};

export default Modal;
