import styled from "styled-components";

export const ModalBody = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }


`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet}) and (orientation: landscape) {
        display: block;
    }
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_small}) {
        padding: 24px;
    }
    
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.phone_small}) {
        display: block;
    }
`

export const Container = styled.div`
    padding: 48px 48px 0;
    width: 500px;
    margin: 0 auto;
    background-color: ${({theme}) => theme.colors.white};
    position: relative;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding: 24px 24px;
        width: 100%;
    }
`;

export const Span = styled.span`
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 12px;
        right: 10px;
    }
`;
