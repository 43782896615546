import React from "react";

export const useEngContactData = () => {
  return {
    metaInfo: {
      title: "Contact",
      ogDescription:
        "We endeavor to do our very best to meet the needs of your business. Trust the professionals and start your partnership together with us today.",
    },
    hero: {
      title: (
        <>
          <span style={{ color: "#fbee00" }}>Contact</span> Us
        </>
      ),
      subtitle:
        "We endeavor to do our very best to meet the needs of your business. Trust the professionals and start your partnership together with us today.",
    },
    address: {
      title: "Contact details",
      abode: {
        title: "Office address",
        text: (
          <>
            ul. Wolności 261 <br />
            37-403 Pysznica <br />
            NIP: 865-242-92-88
          </>
        ),
      },
      info: {
        title: "Office hours: 7:30 - 15:30",
        contactInfo: [
          {
            email: "biuro@wumax.pl",
            title: "Our email",
          },
          {
            number: "+48 691 967 840",
            title: "Our phone number",
          },

          {
            number: "+48 532 202 777",
            title: "Our phone number",
          },
          {
            number: "+48 787 994 777",
            title: "Our phone number",
          },
        ],
      },
    },
    companyData: {
      title: "Address",
      abode: {
        title: "VAT invoice and business registration address",
        text: (
          <>
            Wumax Agata Wermińska <br />
            ul. Eugeniusza Kwiatkowskiego 19 <br />
            37-400 Nisko
            <br />
            NIP: 865-242-92-88
          </>
        ),
      },
      info: {
        title: "Technical support",
        name: "Bartłomiej Wermiński",
        contactInfo: [
          {
            email: "b.wermiński@wumax.pl",
            title: "Technical support email",
          },
          {
            number: "+48 733 112 202",
            title: "Technical support phone number",
          },
        ],
      },
    },
    form: {
      title: "Write to us",
      text: (
        <>
          We encourage to contact with us, <br /> we answer every question.
        </>
      ),
      name: "Your name",
      company: "Company name",
      email: "Your e-mail",
      phone: "Phone number",
      message: "Message",
      button: "Send",
      close: "Close",
    },
  };
};
