import React, {useEffect} from "react";
import Hero from "components/Hero/Hero";
import Section from "components/Section/Section";
import SEO from "components/seo";
import {useDispatch, useSelector} from "react-redux";
import {formModalToggle} from "../../store/modals";
import Modal from "../../components/Modal/Modal";
import {heroToggle} from "../../store/menuColor";
import {SectionsWrappper} from "../../shared/Wrappers/wrappers";

const Homepage = ({data}) => {
  const dispatch = useDispatch()
  const {openForm, locale} = useSelector(state => state.modals);
  useEffect(() => {
    setTimeout(() => dispatch(heroToggle(false)), 500)
  }, [dispatch])

  useEffect(() => {
    if (openForm) {
      document.body.classList.add("blocked");
    } else {
      document.body.classList.remove("blocked");
    }
  });

  const handleModalClose = () => {
    dispatch(formModalToggle())
  }

  return (
    <>
      <SEO
        title={data.metaInfo.title}
        description={data.metaInfo.ogDescription}
      />
      {openForm && <Modal active={openForm} closeModal={handleModalClose} locale={locale}/>
      }
      <Hero data={data.hero}/>
      <SectionsWrappper>
        <Section color="yellow" data={data.sections[0]}/>
        <Section data={data.sections[1]} reverse={true}/>
        <Section data={data.sections[2]} parallax/>
        <Section data={data.sections[3]} reverse={true}/>
        <Section data={data.sections[4]} parallax={true}/>
      </SectionsWrappper>
    </>
  );
};

export default Homepage;
