import { graphql, useStaticQuery } from "gatsby";

export const useHomepagePhotos = () => {
  const query = useStaticQuery(graphql`
    query {
      kennametal: file(
        relativePath: { eq: "image/navbar/menu-foto-kennametal.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      haimer: file(relativePath: { eq: "image/navbar/haimerlogo.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      walter: file(relativePath: { eq: "image/navbar/menu-foto-walter.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      maford: file(relativePath: { eq: "image/navbar/menu-foto-maford.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      yg1: file(relativePath: { eq: "image/navbar/menu-foto-yg1.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      zccusa: file(relativePath: { eq: "image/navbar/menu-foto-zcc.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      vargus: file(relativePath: { eq: "image/navbar/menu-foto-vargus.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      kenTip: file(relativePath: { eq: "image/homepage/kenTip.png" }) {
        childImageSharp {
          fluid(maxHeight: 650, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      toolboss: file(relativePath: { eq: "image/homepage/toolboss.png" }) {
        childImageSharp {
          fluid(maxHeight: 780, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }

      partner: file(
        relativePath: { eq: "image/homepage/homepage-partner.png" }
      ) {
        childImageSharp {
          fluid(maxHeight: 780, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return query;
};
