import { useHomepagePhotos } from "shared/Hooks/homepage/useHomepagePhotos";
import kennmetalVideo from "assets/video/wumax-home.mp4";

const useHomepageData = () => {
  const photos = useHomepagePhotos();
  return {
    metaInfo: {
      title: "WUMAX - Narzędzia skrawające",
      ogDescription:
        "Jesteśmy oficjalnym dystrybutorem marek narzędzi: Kennametal, Haimer oraz Walter. Posiadamy pełen asortyment produktów z branży obróbki metali. Jako przedstawiciel tych producentów stawiamy na jakość, innowację i nowoczesną technologię, dostarczając najlepsze rozwiązania dla Twojego biznesu. Gwarantujemy ponadprzeciętne wsparcie, począwszy od profesjonalnego doradztwa w doborze narzędzi, poprzez kompleksowe oferty, aż po wysyłkę i wsparcie posprzedażowe.",
    },
    hero: {
      title: "Sprzedajemy precyzyjne rozwiązania dla branży",
      titleColor: "obróbki metali",
      subtitle:
        "Jesteśmy oficjalnym dystrybutorem marek narzędzi: Kennametal, Haimer oraz Walter. Posiadamy pełen asortyment produktów z branży obróbki metali. Jako przedstawiciel tych producentów stawiamy na jakość, innowację i nowoczesną technologię, dostarczając najlepsze rozwiązania dla Twojego biznesu. Gwarantujemy ponadprzeciętne wsparcie, począwszy od profesjonalnego doradztwa w doborze narzędzi, poprzez kompleksowe oferty, aż po wysyłkę i wsparcie posprzedażowe.",
    },
    sections: [
      {
        title: "Kennametal",
        subtitle:
          "Kennametal to wiodący dostawca materiałów przemysłowych i narzędzi przeznaczonych do obróbki metali. Ich produkty cechuje najwyższa jakość, precyzja i wytrzymałość, co sprawia, że są światowym liderem w branży. Narzędzia Kennametal to gwarancja niezawodności i wysokiej wydajności.",
        slug: "/kennametal",
        src: photos.kennametal,
        bigImg: false,
        cta: "Zapytaj eksperta",
        checkOffer: "Zobacz pełną ofertę",
        video: kennmetalVideo,
      },
      {
        title: "Wiodący producenci na rynku obróbki metali",
        subtitle:
          "W naszej ofercie znajdują się produkty renomowanych producentów, takich jak Haimer, Walter czy M.A. Ford. Są to wiodące firmy w sektorze obróbki metalu, oferujące najwyższej jakości rozwiązania dla branży. Wysoka klasa, duża wydajność, najlepsze parametry - tym kierujemy się, wybierając produkty znajdujące się w naszej ofercie.",
        slug: "/other",
        gallery: [
          {
            src: photos.haimer,
            big: true,
            alt: "Haimer Logo",
            slug: "/haimer",
          },
          {
            src: photos.walter,
            big: true,
            alt: "Walter Logo",
            slug: "/walter",
          },
          {
            src: photos.maford,
            big: false,
            alt: "M.A. Ford Logo",
            slug: "/pozostali-producenci#maford",
          },
          {
            src: photos.yg1,
            big: false,
            alt: "YG1 Logo",
            slug: "/pozostali-producenci#yg1",
          },
          {
            src: photos.zccusa,
            big: false,
            alt: "ZCC Logo",
            slug: "/pozostali-producenci#zcc",
          },
          {
            src: photos.vargus,
            big: false,
            alt: "Vargus Logo",
            slug: "/pozostali-producenci#vargus",
          },
        ],
        showMoreText: "Zobacz Więcej",
      },

      {
        title: "Najwyższej jakości narzędzia do obróbki metalu",
        subtitle:
          "Posiadamy szeroką ofertę narzędzi do obróbki skrawaniem: cięcia, frezowania, toczenia, gwintowania, żłobienia itd. W naszym asortymencie znajdują się różnego rodzaju wiertła, frezy, adaptery i inne części do obrabiarek CNC. Oferujemy produkty znanych i renomowanych firm, skupiając się na tym, by zawsze dostarczać najwyższą jakość naszym partnerom. Najważniejsze dla nas są najlepsze rozwiązania dla przemysłu, ciągle wspomagające rozwój naszych kontrahentów.",
        src: photos.kenTip,
        bigImg: true,
      },
      {
        title: "Automaty ToolBOSS",

        subtitle:
          "Automaty pozwalające stworzyć system efektywnego zarządzania magazynem i dystrybucją narzędzi. To rozwiązanie umożliwiające optymalizację i zwiększenie produktywności zakładów. Oszczędność czasu, zmniejszenie kosztów administracyjnych i monitoring narzędzi w czasie rzeczywistym - takie zalety niosą ze sobą automaty ToolBOSS.",
        slug: "/toolboss",
        cta: "Zapytaj eksperta",
        src: photos.toolboss,
        checkOffer: "Zobacz pełną ofertę",
        bigImg: true,
      },
      {
        title: "Dobry partner dla branży przemysłu",
        subtitle:
          "Nasza firma stawia na innowację i jakość, oferując usługi nie tylko w zakresie zakupu, ale również doradztwa, opracowywania i wdrażaniu technologii. W ofercie posiadamy nie tylko narzędzia do obróbki metalu firm takich jak Kennametal, Walter czy Haimer, ale również automaty do wydawania narzędzi ToolBOSS. Wspieramy przemysł, oferując zawsze najbardziej zaawansowane technologicznie rozwiązania.",
        src: photos.partner,
        bigImg: true,
      },
    ],
  };
};

export default useHomepageData;
