import React from "react";
import useHomepageData from "lang/index";
import Homepage from "sections/Homepage/Homepage";

const IndexPage = () => {

  const data = useHomepageData();
  return <Homepage data={data}/>;
};

export default IndexPage;
